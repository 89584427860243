import Vue from 'vue';

/**
 * 生成随机字符串
 * @param {Number} len
 * @returns {string}
 */
const randomString = (len = 32) => {
	let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
	/****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
	let maxPos = $chars.length;
	let pwd = '';
	for (let i = 0; i < len; i++) {
		pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
	}
	return pwd;
}

/**
 * 获取get请求参数的值
 * @param {String} name
 * @returns {String||null}
 */
const getQuery = (name) => {
	const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
	const r = window.location.search.substr(1).match(reg);
	if (r != null) {
		return decodeURIComponent(r[2]);
	}
	return null;
};


export default {
	randomString,
	getQuery
}
