<template>
	<div class="app-upload" @click="handleClick">
		<slot></slot>
		<input ref="input" type="file" :accept="accept" :multiple="multiple" style="display: none"
			@change="handleChange">
	</div>
</template>

<script>
	export default {
		props: {
			disabled: Boolean,
			multiple: Boolean,
			max: Number,
			accept: String,
			groupId: {
				type: Number,
				default: -1,
			},
		},
		data() {
			return {
				files: [],
			};
		},
		computed: {
			type: {
				get() {
					if (this.accept === 'image/*') {
						return 'image';
					}
					if (this.accept === 'video/*') {
						return 'video';
					}
				},
			},
		},
		created() {},
		methods: {
			handleClick() {
				if (this.disabled) {
					return;
				}
				this.$refs.input.value = null;
				this.$refs.input.click();
			},
			handleChange(e) {
				if (!e.target.files) return;
				this.uploadFiles(e.target.files);
			},
			uploadFiles(rawFiles) {
				if (this.max && rawFiles.length > this.max) {
					this.$message.error('最多一次只能上传' + this.max + '个文件。')
					return;
				}
				this.files = [];
				for (let i = 0; i < rawFiles.length; i++) {
					const file = {
						_complete: false,
						response: null,
						rawFile: rawFiles[i],
					};
					this.files.push(file);
				}
				this.$emit('start', this.files);
				for (let i in this.files) {
					this.upload(this.files[i]);
				}
			},
			upload(file) {
				let formData = new FormData();
				for (let i in this.fields) {
					formData.append(i, this.fields[i]);
				}
				formData.append('file', file.rawFile, file.rawFile.name);
				this.axios({
					token: true,
					params: {
						s: 'store/upload/upload',
						group_id: this.groupId,
						type: this.type,
					},
					data: formData,
					method: 'post',
				}).then(e => {
					if (e.data.code === 0) {
						this.$message.error(e.data.msg);
					}
					file.response = e;
					file._complete = true;
					this.onSuccess(file);
				}).catch(e => {
					file._complete = true;
				});
			},
			onSuccess(file) {
				this.$emit('success', file);
				let allComplete = true;
				for (let i in this.files) {
					if (!this.files[i]._complete) {
						allComplete = false;
						break;
					}
				}
				if (allComplete) {
					this.$emit('complete', this.files);
				}
			},
		},
	}
</script>
<style>
</style>
