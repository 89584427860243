export default {

	state: {
		navBar: {
			active: '0',
			list: []
		}
	},

	getters: {
		// 首页
		adminIndex(state) {
			if (state.navBar.list.length === 0) {
				return 'error_404'
			}
			let item = state.navBar.list[0]
			if (item) {
				return item.index
			}
		}
	},

	mutations: {
		// 创建菜单
		createNavBar(state, menus) {
			let list = menus
			let breadCrumbList = []
			if (list.length > 0) {
				let active = "0";
				breadCrumbList.push({
					title: list[0].name,
					icon: list[0].icon,
					path: list[0].index
				});
				if (list[0].submenu.length > 0) {
					breadCrumbList.push({
						title: list[0].submenu[0].name,
						path: list[0].submenu[0].index
					});
					active += '-0'
					if (list[0].submenu[0].submenu.length > 0) {
						breadCrumbList.push({
							title: list[0].submenu[0].submenu[0].name,
							path: list[0].submenu[0].submenu[0].index
						});
						active += '-0'
					}
				}
				state.navBar.active = active;
			}
			state.navBar.list = list
			state.navBar.breadCrumbList = breadCrumbList
			state.navBar.isCollapse = false;
			window.localStorage.setItem('navBar', JSON.stringify(state.navBar))
		},

		// 初始化菜单
		initNavBar(state) {
			let navBar = window.localStorage.getItem('navBar')
			navBar = navBar ? JSON.parse(navBar) : {
				active: '0',
				list: [],
				isCollapse: false,
				breadCrumbList: []
			}
			state.navBar = navBar
		},

	},

	actions: {

	}
}
