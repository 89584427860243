let routes = [{
		path: '/',
		name: 'layout',
		param: false,
		redirect: {
			name: 'index/index'
		},
		component: 'layout',
		children: [{
			meta: {
				title: '首页'
			},
			component: 'index/index',
			param: false
		}, {
			meta: {
				title: '用户管理'
			},
			component: 'user/index',
			param: false
		}, {
			meta: {
				title: '海报管理'
			},
			component: 'poster/index',
			param: false
		}, {
			meta: {
				title: '景点管理'
			},
			component: 'scenic/index',
			param: false
		}, {
			meta: {
				title: '福卡管理'
			},
			component: 'focas/index',
			param: false
		}, {
			meta: {
				title: '领取记录'
			},
			component: 'focas/record',
			param: false
		}, {
			meta: {
				title: '素材管理'
			},
			component: 'content.material/index',
			param: false
		}, {
			meta: {
				title: '奖品管理'
			},
			component: 'prize/index',
			param: false
		}, {
			meta: {
				title: '抽奖记录'
			},
			component: 'prize/user_prize',
			param: false
		}, {
			meta: {
				title: '中奖统计'
			},
			component: 'prize.record/index',
			param: false
		}, {
			meta: {
				title: '批量发货'
			},
			component: 'prize.record/batchdelivery',
			param: false
		}, {
			meta: {
				title: '发货物流'
			},
			component: 'prize.express/index',
			param: false
		}, {
			meta: {
				title: '小程序设置'
			},
			component: 'wxapp/setting',
			param: false
		}, {
			meta: {
				title: '管理员列表'
			},
			component: 'store.user/index',
			param: false
		}, {
			meta: {
				title: '管理员添加'
			},
			component: 'store.user/add',
			param: false
		}, {
			meta: {
				title: '管理员编辑'
			},
			component: 'store.user/edit',
			param: 'store_user_id'
		}, {
			meta: {
				title: '个人中心'
			},
			component: 'store.user/renew',
			param: false
		}, {
			meta: {
				title: '权限列表'
			},
			component: 'store.access/index',
			param: false
		}, {
			meta: {
				title: '角色列表'
			},
			component: 'store.role/index',
			param: ''
		}, {
			meta: {
				title: '角色添加'
			},
			component: 'store.role/add',
			param: false
		}, {
			meta: {
				title: '角色编辑'
			},
			component: 'store.role/edit',
			param: 'role_id'
		}, {
			meta: {
				title: '设置'
			},
			component: 'setting/setting',
			param: false
		}, {
			meta: {
				title: '404错误'
			},
			component: 'error/404',
			param: false
		}]
	},
	{
		meta: {
			title: '登录页'
		},
		component: 'passport/login',
		param: false
	},
	{
		path: '*',
		redirect: {
			name: 'index/index'
		},
	}
]

// 获取路由信息方法
let getRoutes = function() {
	// 自动生成路由
	createRoute(routes)
	console.log(routes)
	return routes
}

// 自动生成路由
function createRoute(arr) {
	for (let i = 0; i < arr.length; i++) {
		if (!arr[i].component) return
		// 去除index
		let val = arr[i].component.replace(/\./g, '/')
		// 生成name
		arr[i].name = arr[i].component
		if (arr[i].param) {
			// 生成path
			arr[i].path = '/' + val + '/:' + arr[i].param
		} else {
			// 生成path
			arr[i].path = '/' + val
		}

		// 自动生成component
		let componentFun = import(`../../views/${val}.vue`)
		arr[i].component = () => componentFun
		if (arr[i].children && arr[i].children.length > 0) {
			createRoute(arr[i].children)
		}
	}
}

export default getRoutes()
