<template>
	<div>
		<el-dialog class="app-pick-link" :title="title ? title : '选择链接'" :visible.sync="dialogFormVisible"
			:close-on-click-modal="false" append-to-body>
			<el-tabs v-model="currentTab" @tab-click="hanleClick">
				<el-tab-pane label="商城" name="shop">
					<el-card shadow="never" class="mt-24">
						<div slot="header">
							<span>商城页面</span>
						</div>
						<el-row>
							<el-col :xl="24" class="p-4">
								<template v-for="(item,index) in page.shop.shop">
									<el-tag style="margin-right: 5px;" @click="handleShop(item.value)">{{ item.name }}
									</el-tag>
								</template>
							</el-col>
						</el-row>
					</el-card>
				</el-tab-pane>
				<el-tab-pane label="商品" name="goods">
					<el-form @submit.native.prevent>
						<el-form-item>
							<el-input size="small" v-model="goodsSearch.keyword" autocomplete="off" placeholder="商品名称"
								style="width: 40%"></el-input>
							<el-button size="small"
								@click="toGoodsSearch()">查找商品
							</el-button>
						</el-form-item>
					</el-form>
					<el-table :data="goodsList.data" style="width: 100%">
						<el-table-column prop="goods_id" label="商品ID" width="80"></el-table-column>
						<el-table-column align="center" width="120" label="图片">
							<template slot-scope="scope">
								<viewer>
									<img style="width: 45px;height: 45px;" :src="scope.row.cover_pic">
								</viewer>
							</template>
						</el-table-column>
						<el-table-column align="center" label="商品">
							<template slot-scope="scope">
								<el-tooltip class="item" effect="dark" placement="top">
									<template slot="content">
										<div style="width: 320px;">{{scope.row.name}}</div>
									</template>
									<span style="">{{scope.row.name}}</span>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button plain size="mini" type="primary" @click="handleGoods(scope.row)">选择
								</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div slot="footer" class="dialog-footer">
						<el-pagination :page-size="goodsList.per_page" :current-page.sync="goodsSearch.page"
							style="display: inline-block;float: right;" background @current-change="goodsPageChange"
							layout="prev, pager, next" :total="goodsList.total">
						</el-pagination>
					</div>
				</el-tab-pane>
				<!-- <el-tab-pane label="商品分类" name="category">
					<el-table :default-expand-all="true" row-key="category_id" lazy
						:tree-props="{children: 'child', hasChildren: 'hasChildren'}" border class="mt-3"
						:data="category" style="width: 100%" :header-cell-style="{ background: '#F2F2F2' }">
						<el-table-column prop="category_id" label="分类ID" width="80"></el-table-column>
						<el-table-column prop="name" label="分类名称">
							<template slot-scope="scope">
								{{scope.row.name}}
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button plain size="mini" type="primary" @click="handleCate(scope.row)">选择
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane> -->
				<el-tab-pane label="服务" name="service">
					<el-form @submit.native.prevent>
						<el-form-item>
							<el-input size="small" v-model="serviceSearch.keyword" autocomplete="off" placeholder="服务名称"
								style="width: 40%"></el-input>
							<el-button size="small"
								@click="toServiceSearch()">查找服务
							</el-button>
						</el-form-item>
					</el-form>
					<el-table :data="serviceList.data" style="width: 100%">
						<el-table-column prop="service_id" label="服务ID" width="80"></el-table-column>
						<el-table-column align="center" width="120" label="图片">
							<template slot-scope="scope">
								<viewer>
									<img style="width: 45px;height: 45px;" :src="scope.row.cover_pic">
								</viewer>
							</template>
						</el-table-column>
						<el-table-column align="center" label="服务">
							<template slot-scope="scope">
								<el-tooltip class="item" effect="dark" placement="top">
									<template slot="content">
										<div style="width: 320px;">{{scope.row.name}}</div>
									</template>
									<span style="">{{scope.row.name}}</span>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button plain size="mini" type="primary" @click="handleService(scope.row)">选择
								</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div slot="footer" class="dialog-footer">
						<el-pagination :page-size="serviceList.per_page" :current-page.sync="serviceSearch.page"
							style="display: inline-block;float: right;" background @current-change="servicePageChange"
							layout="prev, pager, next" :total="serviceList.total">
						</el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="自定义页面" name="custom">
					<el-form @submit.native.prevent>
						<el-form-item>
							<el-input size="small" v-model="pageSearch.keyword" autocomplete="off" placeholder="页面名称"
								style="width: 40%"></el-input>
							<el-button size="small"
								@click="toPageSearch()">查找页面
							</el-button>
						</el-form-item>
					</el-form>
					<el-table :data="pageList" style="width: 100%">
						<el-table-column align="center" width="150" label="页面id" prop="page_id"></el-table-column>
						<el-table-column align="center" label="页面名称" prop="name"></el-table-column>
						<el-table-column align="center" label="页面类型">
							<template slot-scope="scope">
								<el-tag type="success" v-if="scope.row.page_type == 10">首页</el-tag>
								<el-tag v-else>自定义页</el-tag>
							</template>
						</el-table-column>
						<el-table-column align="center" label="创建时间" prop="create_time">
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button plain size="mini" type="primary" @click="handlePage(scope.row)">选择
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
		</el-dialog>
		<div @click="dialogFormVisible = !dialogFormVisible" style="display: inline-block">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['app', 'layout'],
		props: {
			title: String,
			params: Object,
		},
		data() {
			return {
				dialogFormVisible: false,
				currentTab: 'shop',
				category: [],
				goodsList: [],
				pageList: [],
				serviceList: [],
				goodsSearch: {
					s: 'store/goods/index',
					keyword: '',
					page: 1,
				},
				pageSearch: {
					s: 'store/page/index',
					keyword: '',
					page: 1,
				},
				serviceSearch: {
					s: 'store/service/index',
					keyword: '',
					page: 1,
				},
				page: {
					shop: {
						shop: [{
							name: '首页',
							value: '/pages/index/index'
						}, {
							name: '分类页',
							value: '/pages/goods/goods'
						}, {
							name: '我的',
							value: '/pages/my/my'
						}, {
							name: '购物车',
							value: '/pages/car/car'
						}, {
							name: '我的订单',
							value: '/pages/order/orderlist'
						}, {
							name: '个性定制',
							value: '/pages/index/customize/customize'
						}],
					},
				}
			}
		},
		created() {
			// this.getCategory();
			this.getPageList();
		},
		methods: {
			toGoodsSearch(){
				this.goodsSearch.page = 1;
				this.getList();
			},
			toServiceSearch(){
				this.serviceSearch.page = 1;
				this.getServiceList();
			},
			toPageSearch(){
				this.getPageList();
			},
			handleCate(category) {
				let page_url = '/pages/goods/list/list?category_id=' + category.category_id
				this.$emit('selected', page_url, this.params);
				this.$emit('input', page_url);
				this.dialogFormVisible = !this.dialogFormVisible
			},
			handleGoods(goods) {
				let page_url = '/goods/detail?goods_id=' + goods.goods_id
				this.$emit('selected', page_url, this.params);
				this.$emit('input', page_url);
				this.dialogFormVisible = !this.dialogFormVisible
			},
			handleService(service) {
				let page_url = '/pages/server/server?service_id=' + service.service_id
				this.$emit('selected', page_url, this.params);
				this.$emit('input', page_url);
				this.dialogFormVisible = !this.dialogFormVisible
			},
			handlePage(page) {
				let page_url = '/pages/index/index?page_id=' + page.page_id
				this.$emit('selected', page_url, this.params);
				this.$emit('input', page_url);
				this.dialogFormVisible = !this.dialogFormVisible
			},
			getCategory() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/goods.category/index'
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.category = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.goodsSearch,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.goodsList = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			getServiceList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.serviceSearch,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.serviceList = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			getPageList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.pageSearch,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.pageList = res.data.data
					console.log(this.pageList)
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 分页
			goodsPageChange(page) {
				this.goodsSearch.page = page;
				this.getList();
			},
			servicePageChange(page) {
				this.serviceSearch.page = page;
				this.getServiceList();
			},
			handleShop(page_url) {
				this.$emit('selected', page_url, this.params);
				this.$emit('input', page_url);
				this.dialogFormVisible = !this.dialogFormVisible
			},
			hanleClick() {},
		},
	}
</script>

<style>
	.app-pick-link .el-checkbox+.el-checkbox {
		margin-left: 0;
	}

	.app-pick-link .checkbox-div-box {
		height: 350px;
		overflow: auto;
	}

	.app-pick-link .edit-img {
		width: 18px;
		height: 18px;
		display: inline-block;
		margin-left: 10px;
		cursor: pointer;
	}

	.app-pick-link .el-dialog {
		width: 800px;
	}

	.app-pick-link .el-checkbox {
		margin-right: 0;
		height: 32px;
	}

	.app-pick-link .el-checkbox__input {
		margin-top: 4px;
	}
</style>
