<template>
	<div class="app-attachment">
		<el-dialog class="app-attachment-dialog" :title="title ? title : '选择文件'" :visible.sync="dialogVisible"
			@opened="dialogOpened" :close-on-click-modal="false" :width="'65%'" top="10vh" append-to-body>
			<template>
				<div v-if="false" class="d-flex">
					<el-button v-if="!showEditBlock" @click="showEditBlock=true">开启编辑</el-button>
					<template v-if="showEditBlock">
						<el-button @click="showEditBlock=false" style="margin-right: 12px">退出编辑模式</el-button>
						<el-checkbox border v-model="selectAll" @change="selectAllChange" label="全选"
							style="margin-right: 12px;margin-bottom: 0"></el-checkbox>
						<el-button :loading="deleteLoading" @click="deleteItems" style="margin-right: 12px">删除
						</el-button>
						<el-dropdown v-loading="moveLoading" trigger="click" :split-button="true" @command="moveItems">
							<span>移动至</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-for="(item, index) in groupList" :command="index" :key="index">
									{{item.group_name}}
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
				</div>


				<div class="d-flex body-box">
					<!-- 左侧 -->
					<div class="left">
						<el-menu class="group-menu" mode="vertical" v-loading="groupListLoading">
							<el-button style="margin-top:12px;margin-left:5%;" type="primary" @click="showAddGroup(-1)">
								添加分组
							</el-button>
							<el-input style="width:90%;margin: 20px 5%" v-model="keyword" placeholder="请输入分类名称搜索">
							</el-input>
							<el-menu-item index="all" @click="switchGroup(-1)">
								<i class="el-icon-tickets"></i>
								<span>全部</span>
							</el-menu-item>
							<template v-for="(item, index) in groupItem">
								<el-menu-item :index="'' + index" @click="switchGroup(index)">
									<div class="d-flex justify-content-between">
										<div style="overflow: hidden;text-overflow: ellipsis">
											<i class="el-icon-tickets"></i>
											<span>{{item.group_name}}</span>
										</div>
										<div class="d-flex">
											<el-button type="text" @click.stop="showAddGroup(index)">编辑</el-button>
											<div style="color:#409EFF;margin:0 2px">|</div>
											<el-button type="text" @click.stop="deleteGroup(index)">删除</el-button>
										</div>
									</div>
								</el-menu-item>
							</template>
						</el-menu>
					</div>

					<!-- 右侧 -->
					<div v-loading="loading" class="d-flex right">
						<div class="search" style="margin-right: 12px">
							<el-input placeholder="请输入名称搜索" v-model="p_keyword" @keyup.enter.native="picSearch"
								class="input-with-select">
								<el-button @click="picSearch" slot="append" icon="el-icon-search"></el-button>
							</el-input>
						</div>
						<div class="app-attachment-list">
							<div class="app-attachment-item app-attachment-upload">
								<app-upload class="align-items-center d-flex justify-content-center "
									v-loading="uploading" :disabled="uploading" @start="handleStart"
									@success="handleSuccess" :multiple="true" :max="10" :groupId="currentGroupId"
									:accept="accept" flex="main:center cross:center"
									style="width: 100px;height: 100px;">
									<div v-if="uploading">{{uploadCompleteFilesNum}}/{{uploadFilesNum}}</div>
									<i v-else class="el-icon-upload"></i>
								</app-upload>
							</div>
							<template v-for="(item, index) in attachments">
								<el-tooltip class="item" effect="dark" :content="item.name" placement="top"
									:open-delay="1">
									<div :key="index"
										:class="'app-attachment-item'+((item.checked&&!showEditBlock)?' checked':'')+(item.selected&&showEditBlock?' selected':'')"
										@click="handleClick(item)">
										<img v-if="item.file_type == 'image'" class="app-attachment-img"
											:src="item.file_path" style="width: 100px;height: 100px;">
										<video controls="controls" v-if="item.file_type == 'video'" class="material-img" style="width: 100px;height: 100px;position: relative">
											<source :src="item.file_path">
										</video>
										<div class="app-attachment-name">{{item.name}}</div>
										<i v-if="false" class="app-attachment-active-icon el-icon-circle-check"></i>
									</div>
								</el-tooltip>
							</template>
						</div>
						<div style="padding: 5px;text-align: right;margin-top:auto;">
							<el-pagination v-if="pagination" background @size-change="handleLoadMore"
								@current-change="handleLoadMore" :current-page.sync="page"
								:page-size="pagination.per_page" layout="prev, pager, next, jumper"
								:total="pagination.total">
							</el-pagination>
						</div>
					</div>
				</div>
				<div style="text-align: right">
					<span v-if="showEditBlock" style="color: #909399">请先退出编辑模式</span>
					<el-button @click="confirm" type="primary" :disabled="showEditBlock">选定</el-button>
				</div>
			</template>
		</el-dialog>
		<!-- 添加分组弹窗 -->
		<el-dialog append-to-body title="分组管理" :visible.sync="addGroupVisible" :close-on-click-modal="false"
			width="25%">
			<el-form @submit.native.prevent label-width="80px" ref="groupForm" :model="groupForm"
				:rules="groupFormRule">
				<el-form-item label="分组名称" prop="group_name" style="margin-bottom: 22px;">
					<el-input v-model="groupForm.group_name" maxlength="8" show-word-limit></el-input>
				</el-form-item>
				<el-form-item style="text-align: right">
					<el-button type="primary" @click="groupFormSubmit('groupForm')" :loading="groupFormLoading">保存
					</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<div style="line-height: normal;" @click="dialogVisible = !dialogVisible"
			:style="'display:'+(display ? display:'inline-block')">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	import appUpload from '@/components/app-upload.vue';
	export default {
		components: {
			appUpload,
		},
		props: {
			display: String,
			title: String,
			max: Number,
			multiple: Boolean,
			params: Object,
			type: {
				type: String,
				default: 'image',
			},
			openDialog: {
				type: Boolean,
				default: false,
			},
		},
		computed: {
			accept: {
				get() {
					if (this.type === 'image') {
						return 'image/*';
					}
					if (this.type === 'video') {
						return 'video/*';
					}
					return '*/*';
				},
			},
		},
		watch: {
			openDialog(newVal, oldVal) {
				this.dialogVisible = newVal;
			},
			dialogVisible(newVal, oldVal) {
				if (!newVal) {
					this.$emit("closed");
				}
			},
			keyword(newVal, oldVal) {
				const groupList = this.groupList;
				let arr = [];
				groupList.map(v => {
					if (v.group_name.indexOf(newVal) !== -1) {
						arr.push(v);
					}
				});
				this.groupItem = arr;
			}
		},
		data() {
			return {
				dialogVisible: false,
				showEditBlock: false,
				attachments: [],
				uploading: false,
				groupListLoading: false,
				addGroupVisible: false,
				page: 1,
				groupForm: {
					group_id: null,
					group_name: '',
				},
				groupFormRule: {
					group_name: [{
						required: true,
						message: '请填写分组名称。',
					}],
				},
				groupFormLoading: false,
				selectAll: false,
				deleteLoading: false,
				groupList: [],
				groupItem: [],
				moveLoading: false,
				keyword: '',
				p_keyword: '',
				currentGroupId: -1,
				loading: false,
				pagination: null,
				uploadCompleteFilesNum: 0,
				uploadFilesNum: 0,
			}
		},
		created() {},
		methods: {
			confirm() {
				this.$emit('selected', this.checkedAttachments, this.params);
				this.dialogVisible = false;
				const urls = [];
				for (let i in this.checkedAttachments) {
					urls.push(this.checkedAttachments[i].file_path);
				}
				for (let i in this.attachments) {
					this.attachments[i].checked = false;
				}
				this.checkedAttachments = [];
				if (!urls.length) {
					return;
				}
				if (this.multiple) {
					this.$emit('input', urls);
				} else {
					this.$emit('input', urls[0]);
				}
			},
			picSearch() {
				this.page = 1;
				this.loading = true;
				this.loadGroups();
				this.loadList({});
			},
			dialogOpened() {
				if (!this.attachments || !this.attachments.length) {
					this.loading = true;
					this.loadGroups();
					this.loadList();
				}
			},
			handleLoadMore(currentPage) {
				this.page = currentPage;
				this.loading = true;
				this.loadList();
			},
			handleClick(item) {
				if (this.showEditBlock) {
					this.selectItem(item);
					return;
				}
				if (item.checked) {
					item.checked = false;
					for (let i in this.checkedAttachments) {
						if (item.file_id === this.checkedAttachments[i].file_id) this.checkedAttachments.splice(i, 1);
					}
					return;
				}
				if (this.multiple) {
					let checkedCount = 0;
					for (let i in this.attachments)
						if (this.attachments[i].checked) checkedCount++;
					if (this.max && !item.checked && checkedCount >= this.max) return;
					item.checked = true;
					this.checkedAttachments.push(item);
				} else {
					for (let i in this.attachments) this.attachments[i].checked = false;
					item.checked = true;
					this.checkedAttachments = [item];
				}
			},
			loadList() {
				let self = this;
				self.noMore = false;
				self.selectAll = false;
				self.loading = true;
				self.attachments = [];

				self.axios({
					token: true,
					params: {
						s: 'store/upload.library/lists',
						type: this.type,
						page: this.page,
						keyword: this.keyword,
					},
					method: 'get',
				}).then(e => {
					if (e.data.code === 1) {
						for (let i in e.data.data.data) {
							e.data.data.data[i].checked = false;
							e.data.data.data[i].selected = false;
							e.data.data.data[i].duration = null;
						}
						self.attachments = e.data.data.data;
						self.pagination = {
							per_page: e.data.data.per_page,
							total: e.data.data.total
						}
						self.checkedAttachments = [];
						self.loading = false;
					} else {
						this.$message.error(e.data.msg);
						this.dialogVisible = false;
					}
				}).catch(err => {

				})
			},

			loadGroups() {
				this.groupListLoading = true;
				let self = this;
				self.groupListLoading = true;
				self.axios({
					token: true,
					params: {
						s: 'store/upload.Library/group',
						type: this.type,
					},
					method: 'get',
				}).then(res => {
					self.groupListLoading = false;
					if (res.data.code === 1) {
						self.groupList = res.data.data;
						self.groupItem = res.data.data;
					} else {
						self.$message.error(e.data.msg);
					}
				}).catch(err => {
					self.groupListLoading = false;
				})
			},

			handleSuccess(file) {
				if (file.response && file.response.data && file.response.data.code === 1) {
					const newItem = {
						checked: false,
						selected: false,
						create_time: file.response.data.data.create_time,
						extension: file.response.data.data.extension,
						file_id: file.response.data.data.file_id,
						file_name: file.response.data.data.file_name,
						file_path: file.response.data.data.file_path,
						file_size: file.response.data.data.file_size,
						file_type: file.response.data.data.file_type,
						file_url: file.response.data.data.file_url,
						group_id: file.response.data.data.group_id,
						storage: file.response.data.data.storage,
					};
					this.attachments.unshift(newItem);
					this.uploadCompleteFilesNum++;
					this.uploading = false;
				}
			},

			handleStart(files) {
				this.uploading = true;
				this.uploadFilesNum = files.length;
				this.uploadCompleteFilesNum = 0;
			},

			showAddGroup(index) {
				if (index > -1) {
					this.groupForm.group_id = this.groupItem[index].group_id;
					this.groupForm.group_name = this.groupItem[index].group_name;
				} else {
					this.groupForm.group_id = null;
					this.groupForm.group_name = '';
				}
				this.groupForm.edit_index = index;
				this.addGroupVisible = true;
			},

			deleteGroup(index) {
				this.$confirm('是否确认将分组删除', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					self.axios({
						token: true,
						params: {
							s: 'store/upload.Library/deleteGroup',
							group_id: this.groupItem[index].group_id,
						},
						method: 'get',
					}).then(res => {
						if (res.data.code === 1) {
							this.$message.success(res.data.msg);
							this.groupItem.splice(index, 1);
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(err => {})
				}).catch(() => {});
			},

			groupFormSubmit(formName) {
				let self = this;
				this.$refs[formName].validate(valid => {
					if (valid) {
						self.groupFormLoading = true;
						self.axios({
							token: true,
							params: {
								s: 'store/upload.Library/editGroup',
							},
							data: Object.assign({}, this.groupForm, {
								group_type: this.type
							}),
							method: 'post',
						}).then(e => {
							self.groupFormLoading = false;
							if (e.data.code === 1) {
								self.$message.success(e.data.msg);
								self.addGroupVisible = false;
								if (self.groupForm.edit_index > -1) {
									self.groupItem[self.groupForm.edit_index] = e.data.data;
								} else {
									self.groupList.push(e.data.data);
								}
							} else {
								self.$message.error(e.data.msg);
							}
						}).catch(e => {
							self.groupFormLoading = false;
						});
					}
				})
			},

			switchGroup(index) {
				this.attachments = [];
				this.page = 1;
				this.loading = true;
				this.currentGroupId = index > -1 ? this.groupItem[index].group_id : -1;
				this.loadList();
			},

			moveItems(index) {
				const itemIds = [];
				for (let i in this.attachments) {
					if (this.attachments[i].selected) {
						itemIds.push(this.attachments[i].file_id);
					}
				}

				if (!itemIds.length) {
					this.$message.warning('请先选择需要移动的图片。');
					return;
				}
				let self = this;
				this.$confirm('确认移动所选的' + itemIds.length + '张图片？', '提示', {
					type: 'warning'
				}).then(() => {
					self.moveLoading = true;
					self.axios({
						token: true,
						params: {
							s: 'store/upload.Library/moveFiles',
							group_id: this.groupItem[index].group_id,
						},
						data: {
							fileIds: itemIds,
						},
						method: 'post',
					}).then(e => {
						self.moveLoading = false;
						if (e.data.code === 1) {
							self.$message.success(e.data.msg);
							self.switchGroup(index);
						} else {
							self.$message.error(e.data.msg);
						}
					}).catch(e => {
						self.moveLoading = false;
					});
				}).catch(() => {});
			},


			selectAllChange(value) {
				for (let i in this.attachments) {
					this.attachments[i].selected = value;
				}
			},

			deleteItems() {
				const itemIds = [];
				for (let i in this.attachments) {
					if (this.attachments[i].selected) {
						itemIds.push(this.attachments[i].file_id);
					}
				}

				if (!itemIds.length) {
					this.$message.warning('请先选择需要处理的图片。');
					return;
				}

				let self = this;
				self.$confirm('是否确认将选中素材放入回收站中', '提示', {
					type: 'warning'
				}).then(() => {
					self.deleteLoading = true;
					self.axios({
						token: true,
						params: {
							s: 'store/upload.Library/deleteFiles',
						},
						data: {
							fileIds: itemIds,
						},
						method: 'post',
					}).then(e => {
						self.deleteLoading = false;
						if (e.data.code === 1) {
							self.$message.success(e.data.msg);
							for (let i in itemIds) {
								for (let j in self.attachments) {
									if (self.attachments[j].file_id == itemIds[i]) {
										self.attachments.splice(j, 1);
										break;
									}
								}
							}
						} else {
							self.$message.error(e.data.msg);
						}
					}).catch(e => {
						self.deleteLoading = false;
					});
				}).catch(() => {});
			},
		},
	}
</script>

<style scoped>
	.body-box {
		border: 1px solid #e3e3e3;
		margin-bottom: 10px;
		min-height: 300px;
		width: 100%;
	}

	.body-box .left {
		border-right: 1px solid #e3e3e3
	}

	.body-box .right {
		width: calc(100% - 230px);
		height: 100%;
		flex-direction: column;
	}

	.app-attachment-list {
		padding: 5px;
		/* width: 960px; */
	}

	.app-attachment-list * {
		box-sizing: border-box;
	}

	.app-attachment-list:after {
		clear: both;
		display: block;
		content: " ";
	}

	.app-attachment-item {
		display: inline-block;
		/*box-shadow: 0 0 0 1px rgba(0, 0, 0, .15);*/
		cursor: pointer;
		position: relative;
		float: left;
		width: 120px;
		height: 140px;
		margin: 7.5px;
		text-align: center;
		padding: 10px 10px 0;
	}

	.app-attachment-active-icon {
		position: absolute;
		right: 5px;
		top: 5px;
		font-size: 28px;
		color: #409EFF;
		text-shadow: 0 0 1px rgba(255, 255, 255, 0.75);
		opacity: 0;
	}

	.app-attachment-item:hover {
		/*del-box-shadow: 0 0 0 1px rgba(84, 200, 255, 0.41);*/
	}

	.app-attachment-item.checked,
	.app-attachment-item.selected {
		box-shadow: 0 0 0 1px #1ed0ff;
		background: #daf5ff;
		border-radius: 5px;
	}

	.app-attachment-item.checked .app-attachment-active-icon,
	.app-attachment-item.selected .app-attachment-active-icon {
		opacity: 1;
	}

	.app-attachment-item .app-attachment-img {
		display: block;
	}

	.app-attachment-item .file-type-icon {
		width: 30px;
		height: 30px;
		border-radius: 30px;
		background: #666;
		color: #fff;
		text-align: center;
		line-height: 30px;
		font-size: 24px;
	}

	.app-attachment-upload {
		box-shadow: none;
		border: 1px dashed #b2b6bd;
		height: 100px;
		width: 100px;
		margin: 17.5px;
		padding: 0;
	}

	.app-attachment-upload i {
		font-size: 30px;
		color: #909399;
	}

	.app-attachment-upload:hover {
		box-shadow: none;
		border: 1px dashed #409EFF;
	}

	.app-attachment-upload:hover i {
		color: #409EFF;
	}

	.app-attachment-upload:active {
		border: 1px dashed #20669c;
	}

	.app-attachment-upload:active i {
		color: #20669c;
	}

	.app-attachment-dialog .group-menu {
		border-right: none;
		width: 230px;
	}

	.app-attachment-dialog .group-menu .el-menu-item {
		padding-left: 10px !important;
		padding-right: 10px;
	}

	.app-attachment-dialog .group-menu .el-menu-item .el-button {
		padding: 3px 0;
	}

	.del-app-attachment-dialog .group-menu .el-menu-item .el-button:hover {
		background: #e2e2e2;
	}

	.app-attachment-dialog .group-menu .el-menu-item .el-button i {
		margin-right: 0;
	}

	.app-attachment-simple-upload {
		width: 100% !important;
		height: 120px;
		border: 1px dashed #e3e3e3;
		cursor: pointer;
	}

	.app-attachment-simple-upload:hover {
		background: rgba(0, 0, 0, .05);
	}

	.app-attachment-simple-upload i {
		font-size: 32px;
	}


	.app-attachment-video-cover {
		background-size: cover;
		background-position: center;
		width: 100%;
		height: 100%;
	}

	.app-attachment-video-info {
		background: rgba(0, 0, 0, .35);
		color: #fff;
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 1px 3px;
		font-size: 14px;
	}

	.app-attachment-video-name {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		font-size: 12px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.app-attachment-dialog .app-attachment-name {
		color: #666666;
		margin-top: 5px;
		font-size: 13px;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
	}

	.app-attachment-dialog .search {
		margin-top: 10px;
		margin-left: 20px;
		width: 250px;
	}

	.app-attachment-dialog .search .el-input__inner {
		border-right: 0;
	}

	.app-attachment-dialog .search .el-input__inner:hover {
		border: 1px solid #dcdfe6;
		border-right: 0;
		outline: 0;
	}

	.app-attachment-dialog .search .el-input__inner:focus {
		border: 1px solid #dcdfe6;
		border-right: 0;
		outline: 0;
	}

	.app-attachment-dialog .search .el-input-group__append {
		background-color: #fff;
		border-left: 0;
		width: 10%;
		padding: 0;
	}

	.app-attachment-dialog .search .el-input-group__append {
		background-color: #fff;
		border-left: 0;
		width: 10%;
		padding: 0;
	}

	.app-attachment-dialog .search .el-input-group__append .el-button {
		padding: 0;
	}

	.app-attachment-dialog .search .el-input-group__append .el-button {
		margin: 0;
	}

	.app-attachment-dialog .scrollbar {
		height: 100%;
	}
</style>
